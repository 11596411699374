.loading-container {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    min-width: 360px;
    max-width: 878px;
    overflow: hidden;
    border-radius: 10px;
    margin-top: 8px;

}

.loading-chart {
    display: flex;
    position: relative;
    background-color: var(--card-color);
    border-color: var(--hover-color);
    border-style: none;
    border-width: 1.4px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    height: 144px;
    justify-content: center;
    align-items: center;
}

.loading-animation {
    display: flex;
    position: relative;
    flex-direction: row;
}



.loading-circle {
    display: flex;
    position: relative;
    background-color: var(--secondary-color);
    width: 16px;
    height: 16px;
    border-radius: 8px;
    margin: 8px;
}


@keyframes blink {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.33;
    }
}

.blink-1 {
    animation: blink 2s infinite;
}

.blink-2 {
    animation: blink 2s infinite;
    animation-delay: 0.67s;
    /* 1/3 de
    la duración total */
}

.blink-3 {
    animation: blink 2s infinite;
    animation-delay: 1.33s;
    /* 2/3 de la duración total */
}