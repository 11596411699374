.MainMenu {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  width: 80%;
  overflow: hidden;
  max-width: 1550px;
  padding-top: 24px;
  padding-bottom: 24px;

}

.Right-Group {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
}

.AppLogo {
  fill: var(--main-menu);
  width: 150px;
  height: auto;
  z-index: 100;
}

nav {
  display: flex;
  position: relative;
  overflow: hidden;
  align-items: center;
  margin-left: 48px;
}

@media screen and (max-width: 1000px) {
  nav {
    display: none;
  }
}

nav ul {
  display: flex;
  flex-direction: row;
  margin: 0%;
  text-decoration: none;

}


nav ul li {
  margin-right: 48px;

}

/* Estilo para el enlace */
nav ul li a {
  text-decoration: none;
  color: var(--main-menu);
  margin: 0%;

}

/* Cambia el color del texto al pasar el cursor sobre el enlace */
nav ul li a:hover {

  color: var(--secondary-color);
}

nav .selected {
  text-decoration: underline;
}

.themeButton {
  display: flex;
  align-items: center;
  background-color: black;
  color: var(--main-menu);
  border-style: none;
  border-radius: 4px;
  padding: 4px;
  right: 0px;
}

.themeButton:hover {
  border-color: var(--hover-color);

  color: var(--secondary-color)
}