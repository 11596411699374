body {
  margin: 0;
  font-family: 'Nirmala UI Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Estiliza la barra de desplazamiento en sí */
body::-webkit-scrollbar {
  width: 6px; /* Ancho de la barra de desplazamiento vertical */
  height: 6px; /* Altura de la barra de desplazamiento horizontal */
  background-color: var(--background-color);
}

/* Estiliza el fondo de la barra de desplazamiento */
body::-webkit-scrollbar-track {
  background: var(--background-color);
  border-radius: 2px;
}

/* Estiliza el pulgar (la parte móvil) de la barra de desplazamiento */
body::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 4px;
}

/* Estiliza el pulgar al pasar el ratón por encima */
body::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
}
