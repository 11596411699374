.project {
    display: flex;
    flex-direction: column;
    color: var(--hover-color);
    padding-bottom: 128px;
    font-size: 18px;
    background-color: snow;  
}

.image-and-title {
    display: flex;
    position: relative;
}


.project-title {
    color: var(--ternary-color);
    position: absolute;
    width: 70%;
    left: 10%;
    top: 64px;
    min-width: 800px;
    font-size: 55px;  
}

.project .description {
    padding-top: 64px;
    padding-left: 10%;
    padding-right: 10%;
    color: black;
}

