.about {
    display: flex;
    flex-direction: column;
    color: var(--hover-color);
    padding-top: 64px;
    padding-left: 10%;
    padding-right: 10%;
    font-size: 24px;
    background-color: snow;
    color: black
}

.about .base-image {
    width: 100%;
    min-width: 1400px;
}
.graphics-images {
    display: flex;
    position: relative;
    flex-direction: row;
    align-content: center;
    min-width: 1400px;  
}

.graphics-a{
    margin-right: 24px;
    height: 600px;
}

.graphics-b{
    position: absolute;
    margin-left: 24px;
    height: 600px;
    right: 0px;
}
