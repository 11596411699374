.api {
    display: flex;
    flex-direction: column;
    color: var(--hover-color);
    background-color: var(--background-color);
    font-size: 18px;
    padding-top: 64px;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 64px;
}

.api h4{
    padding-top: 32px;

}

.description {
   padding: 0;
}

.code-card {
    background-color: var(--card-color);
    padding: 24px;
    border-radius: 8px;
    color: var(--secondary-color);
}