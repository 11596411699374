.panel-container {
  display: flex;
  position: relative;
  justify-content: center;
  width: 80%;
  min-width: 360px;
  max-width: 878px;
  overflow: hidden;
  border-radius: 10px;
}

.panel-chart {
  display: flex;
  position: relative;
  background-color: var(--card-color);
  border-color: var(--hover-color);
  border-style: none;
  border-width: 1.4px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  min-height: 144px;
}

.app-logo {
  display: flex;
  position: absolute;
  height: 20px;
  left: 180px;
}

.logo {
  display: flex;
  fill: var(--primary-color);
}

.copy-button {
  background: none;
  border: none;
  display: flex;
  position: absolute;
  right: 8px;
  top: 6px;
  color: var(--grid-color);
}

.copy-button:hover {
  color: var(--secondary-color);
}

.download-button {
  background: none;
  border: none;
  display: flex;
  position: absolute;
  right: 42px;
  top: 6px;
  color: var(--grid-color);
}

.download-button:hover {
  color: var(--secondary-color);
}

.axis {
  font-family: inherit;
  line-break: normal;
  color: black
    /* Color del texto */
}

.axis text {
  font-family: inherit;
  font-size: 12px;
  fill: var(--primary-color);
  /* Color del texto */
}

.axis path,
.axis line {
  fill: none;
  stroke: #333;
  /* Color de la línea del eje */
}

.grid-line {
  stroke: var(--grid-color);
  /* Aquí estableces la opacidad deseada */
}