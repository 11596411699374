.Description {
  display: flex;
  flex-direction: column;
  min-height: 64px;
  color: var(--hover-color);
  background-color: var(--background-color);
  align-items: center;
  justify-content: center;
  font-size: 28px;
  border-bottom-style: solid;
  border-color: var(--primary-color);
  border-width: 1px;
  padding: 16px;
}

.Description p {
  color: var(--primary-color);
  font-size: 14px;
}

.Graph-Menu {
  background-color: var(--background-color);
  min-height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: var(--hover-color);
  position: sticky;
  top: 0;
  z-index: 100;
  font-size: 14px;
}

.Graph-Menu .button {
  background-color: var(--background-color);
  color: var(--hover-color);
  border-style: solid;
  border-color: var(--primary-color);
  border-radius: 4px;
  border-width: 1px;
  height: 28px;
  margin-left: 4px;
  margin-right: 4px;
  font-size: 14px;
}

.Graph-Menu .selected {
  /* Estilo del botón cuando está seleccionado */
  background-color: var(--grid-color);
  border-color: var(--hover-color);
}

.Separator {
  background-color: var(--primary-color);
  width: 1px;
  height: 24px;
}

.Graph-Menu :hover {

  border-color: var(--secondary-color);
  color: var(--secondary-color)
}

.icon-flipped {
  transform: rotate(-180deg);
  transition: transform 0.3s ease;
}

.material-symbols-outlined {
  transition: transform 0.3s ease;
}

.graphics-frame {
  display: flex;
  flex-direction: column;
  padding-bottom: 128px;
  background-color: var(--background-color);
}

.graphics{
  position: relative;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding-top: 8px;
}

